@import "styles/core.scss";
.fillContainer {
  @include flex-row;
  gap: var(--space-8);
  overflow: hidden;

  button {
    overflow: hidden;
  }
}

.container {
  @include flex-row;
  gap: var(--space-16);
  justify-content: space-between;
  overflow: hidden;

  button {
    overflow: hidden;
  }
}

.leftButtons {
  @include flex-row;
  gap: var(--space-8);
  overflow: hidden;
}

.rightButtons {
  @include flex-row;
  gap: var(--space-8);
  overflow: hidden;
}
