@import "styles/core.scss";
.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-scrim-light);
  z-index: 1003;
  display: flex;
  justify-content: center;
  align-items: center;

  @include page-width-sm-down {
    align-items: flex-end;
  }

  &.priority {
    z-index: 1004;
  }

  &.transparent {
    background-color: transparent;
    pointer-events: none;

    .modal {
      pointer-events: all;
    }
  }

  &:not(.isVisible) {
    opacity: 0;

    .modal {
      transform: translateY(64px);
    }
  }

  &.isVisible {
    opacity: 1;

    .modal {
      transform: translateY(0);
    }
  }

  &.isAnimating {
    animation: fade-in 0.5s ease-in-out forwards;

    .modal {
      animation: slide-in 0.5s ease-out forwards;
    }

    &.isVisible {
      animation-direction: reverse;

      .modal {
        animation-direction: reverse;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(64px);
  }
  100% {
    transform: translateY(0);
  }
}

.modal {
  @include flex-column;
  position: absolute;
  overflow: hidden;
  background-color: var(--color-background-primary);
  border-radius: var(--radius-xl);
  max-width: calc(100% - 2 * var(--space-48));
  max-height: 85dvh;

  &.isFullHeight {
    height: calc(100dvh - var(--space-200));
  }

  &.sm {
    width: 320px;
  }

  &.md {
    width: 480px;
  }

  &.lg {
    width: 640px;
  }

  &.xl {
    width: 900px;
  }

  @include page-width-sm-down {
    max-width: 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &.sm,
    &.md,
    &.lg,
    &.xl {
      width: 100vw;
    }
  }
}

.header {
  @include flex-row;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-32) var(--space-32) var(--space-16) var(--space-32);

  border-bottom: var(--borders-weight-sm) solid transparent;
  transition: border-color 0.2s ease-out;

  &:not(.imageModal).bottomBorder {
    border-color: var(--color-border-separator);
  }

  @include page-width-sm-down {
    padding: var(--space-16) var(--space-24);
  }

  &.imageModal {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color-background-primary); // Needed to set the background color with "currentColor" in the tsx file
  }

  .closeButton {
    &:focus-visible {
      box-shadow: none;
    }
  }
}

.buttonPlaceholder {
  width: 36px; // Match sm IconButton
}

.titleContainer {
  @include flex-column;
  flex: 1;
  gap: var(--space-100);
  margin-bottom: var(--space-16);

  @include page-width-sm-down {
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

.scrollContainer {
  flex: 1;
  overflow: auto;
}

.headerImageContainer {
  margin-bottom: var(--space-24);

  img {
    display: block;
    width: 100%;
  }
}

.contents {
  padding: 0 var(--space-32) var(--space-24) var(--space-32);

  @include page-width-sm-down {
    padding: var(--space-24);
  }
}

.footer {
  width: 100%;
  padding: var(--space-24) var(--space-32);

  @include page-width-sm-down {
    padding: var(--space-24);
  }

  &:has(.pagedProgressBar) {
    padding-top: var(--space-8);

    @include page-width-sm-down {
      padding-top: var(--space-8);
    }
  }

  border-top: var(--borders-weight-sm) solid transparent;
  transition: border-color 0.2s ease-out;

  &.topBorder {
    border-color: var(--color-border-separator);
  }
}

.pagedProgressBar {
  width: 100%;
  margin-bottom: var(--space-16);
}

.caption {
  @include text-100;
  color: var(--color-text-secondary);
  margin-top: var(--space-200);
  text-align: center;
}

.headerImageV2 {
  overflow-x: hidden;
  perspective: 10px;
  perspective-origin: top;

  .headerImageContainer {
    transform-style: preserve-3d;
    z-index: -1; // behind content
    position: relative;
    height: 180px;

    @include page-width-md-up {
      height: 240px;
    }

    img {
      position: absolute;
      top: 90px;
      transform: translateZ(-10px) scale(2);

      @include page-width-md-up {
        top: 120px;
      }
    }

    margin-bottom: 0;
  }

  .contents {
    background-color: var(--color-background-primary);
    border-top-left-radius: var(--radius-lg);
    border-top-right-radius: var(--radius-lg);
    margin-top: calc(var(--space-24) * -1);
    padding-top: var(--space-24);

    @include page-width-md-up {
      margin-top: calc(var(--space-32) * -1);
      padding-top: var(--space-32);
    }
  }
}

.imageModalV2 {
  z-index: 1;
}
